function checkLogo(imgUrl) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.addEventListener('load', function () {
            const {
                naturalHeight: nH,
                naturalWidth: nW
            } = this;
            (nH < 2 || nW < 2) ? reject() : resolve();
        }, false);
        img.addEventListener('error', () => reject(), false);
        img.src = imgUrl;
    });
}

function setBgImg(container) {
    return (url) => container.style.backgroundImage = `url(${url})`;
}

export default (headerSelector, logoSelector) => {
    const header = document.querySelector(headerSelector);

    if (header === null) {
        return;
    }

    const logoContainer = header.querySelector(logoSelector);

    if (logoContainer === null || typeof(logoContainer.dataset) === 'undefined') {
        return;
    }

    const { src: customImg, fallback: fallbackImg } = logoContainer.dataset;

    if (!customImg && !fallbackImg) {
        return;
    }

    const setLogoBgImg = setBgImg(logoContainer);

    if (customImg) {
        checkLogo(customImg)
            .then(() => setLogoBgImg(customImg))
            .catch(() => setLogoBgImg(fallbackImg));
    }
    else {
        setLogoBgImg(fallbackImg);
    }
};