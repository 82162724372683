/* global INBOX */
import inx from 'inx';

export const setBadge = function (badgeOpts) {
    const badgeContainer = document.querySelector(badgeOpts.containerSelector);
    if (!badgeContainer) {
        return;
    }

    const existingBadge = badgeContainer.querySelector(badgeOpts.selector);
    if (!badgeOpts.count) {
        if (existingBadge) {
            existingBadge.remove();
        }
        return;
    }

    const type = badgeOpts.selector.slice(1);

    const newBadge =  `<span 
         title="${badgeOpts.title} (${badgeOpts.count})"
         class="badge ${type} badge-indicator on top-right"
         style="background-color:${badgeOpts.bgColor}"
         ></span>`;
    if (existingBadge) {
        const div = document.createElement('div');
        div.innerHTML = newBadge;
        existingBadge.replaceWith(div.firstChild);
    } else {
        badgeContainer.innerHTML = badgeContainer.innerHTML + newBadge;
    }
};

export default function() {
    const { i18n: { badge: { recentMessages } } } = inx;

    const renderBadgeOnDataFetched = function (evt, { recent }) {
        setBadge({
            containerSelector: '.btn_i3x-side-menu .num-wrap',
            title: recentMessages,
            selector: '.num-recent',
            count: recent,
            bgColor: '#ce2127' // Replace with imported constant when IE11 will RIP
        });
    };

    INBOX.observer.observe('mailDataFetched', renderBadgeOnDataFetched);
}
