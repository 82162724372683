// /* global INBOX */
/* eslint-disable */
import swipe from '../modules/swipe';

// // Workaround for sidebar on small screens.
// // If sidebar height is bigger than viewport, add vertical scroll
// function checkSidebarScroll(){
//     const sideNav = $('#side-nav').height();
//
//     const sideWrapperEl = document.querySelector('#side-menu .side-wrapper');
//     if ((sideNav + 100) > window.offsetHeight) {
//         sideWrapperEl.style.overflowY = 'auto';
//         sideWrapperEl.style.overflowX = 'hidden';
//     }
//     else {
//         sideWrapperEl.removeAttribute('style');
//     }
// }

// Info: This function is global and is called from banner system
window.affixTop = offTop => {
    //portal specific code
    if (inx && inx.portal && inx.portal.refocus_login_form !== 'undefined') {
        inx.portal.refocus_login_form();
    }
};

function gaSideMenuSend(action) {
    if (window.ga) {
        window.ga('otherTracker.send', 'event', 'Toolbar', action, 'vertical', 1);
    }
}

document.addEventListener("DOMContentLoaded", (event) => {
    // Sidebar menu trigger Functions
    const offcanvasEl = document.querySelector('#side-menu');
    if (!offcanvasEl) {
        return;
    }
    //todo hide close icon faster
    const sideMenuCloseEl = document.querySelector('.side-menu-close');
    offcanvasEl.addEventListener('hide.bs.offcanvas', function () {
        sideMenuCloseEl && sideMenuCloseEl.classList.add('visually-hidden');
    });

    offcanvasEl.addEventListener('show.bs.offcanvas', event => {
        sideMenuCloseEl && sideMenuCloseEl.classList.remove('visually-hidden');
        offcanvasEl.style.display = 'block'; //by default it's display: none because to not load product-icons font file needlesly
    });

    offcanvasEl.addEventListener('shown.bs.offcanvas', event => {
        gaSideMenuSend('Open');
    });

    offcanvasEl.addEventListener('hidden.bs.offcanvas', event => {
        gaSideMenuSend('Close');
    });

    if (sideMenuCloseEl) {
        sideMenuCloseEl.addEventListener('click', function() {
            sideMenuCloseEl.classList.toggle('visually-hidden');
            const offcanvasObj = bootstrap.Offcanvas.getInstance(offcanvasEl);
            offcanvasObj.hide();
        });
    }

    //is superuser clicking profile redirects to pass change so support has easier access to it
    if (inx && inx.params.isSuper) {
        const headerNavItem = document.querySelector('#header-nav-user');
        if (headerNavItem) {
            headerNavItem.onclick = (e) => {
                e.preventDefault();
                if (document.querySelector('.user-detail')) {
                    window.location = document.querySelector('.user-detail').getAttribute('data-profileoverviewurl');
                }
            };
        }
    }
    const addSelector = inx && inx.params.isSuper ? '' : ',#header-nav-user';
    const sideTriggerEl = document.querySelector('#side-trigger'+addSelector);
    if (sideTriggerEl) {
        document.querySelector('#side-trigger'+addSelector).addEventListener('click', function() {
            const offcanvasObj = bootstrap.Offcanvas.getOrCreateInstance(offcanvasEl);
            offcanvasObj.show();
        });
    }

    // Sidebar swipe to right to close sidebar
    if (inx.params.isMobile) {
        swipe(document.querySelector('#side-menu'), 'right', function() {
            const offcanvasObj = bootstrap.Offcanvas.getOrCreateInstance(offcanvasEl);
            offcanvasObj.hide();
            return false;
        });
    }
});
