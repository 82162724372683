import inx from 'inx';

export default (params) => {
    // console.dir(params);
    const
        modalClassName = 'promo-modal',
        {
            promoID,
            theme
        } = params,
        className = [
            modalClassName,
            `${modalClassName}_theme-${theme}`,
            params.isXSDevice ? '' : `${modalClassName}_center-vertical`
        ].join(' ');
    // Template based on bootstrap 3 modal
    return `
        <div 
            class="modal fade new-promo-modal ${className}"
            id="${promoID}"
            role="dialog"
            tabindex="-1"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="header">
                        <button class="btn-close" data-bs-action="close" data-action="close" title="${inx.i18n.mailPlusPromo.close}" type="button"></button>
                    </div>
                    <div class="modal-body">
                        <p>${inx.i18n.mailPlusPromo.body} <span>${inx.i18n.mailPlusPromo.body1}</span></p>
                        <div class="feature-list">
                            <p>${inx.i18n.mailPlusPromo.feature1}</p>
                            <p>${inx.i18n.mailPlusPromo.feature2}</p>
                            <p>${inx.i18n.mailPlusPromo.feature3}</p>
                            <p>${inx.i18n.mailPlusPromo.feature4}</p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="modal-button_primary w-100" data-action="upgrade" type="button">${inx.i18n.mailPlusPromo.txt1}</button>
                        <button class="modal-button_default w-100" data-action="later" type="button">${inx.i18n.mailPlusPromo.txt2}</button>
                    </div>
                </div>
            </div>
        </div>
    `;
};
