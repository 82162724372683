// const positionLangDropDown = ({ target }) => {
//     const dropDown = target.parentNode.querySelector('.dropdown-menu');
//     const icon = target.querySelector('.ico-lang');
// };

const goToLanguage = (lang) => {
    const url = new URL(location.href);
    const params = new URLSearchParams(url.search);
    params.set('language', lang);
    params.set('persist_language', 1);
    url.search = params.toString();
    location.href = url.toString();
};

// Provides visual feedback while loading new language
const changeLanguage = (e) => {
    const liTag = e.target.closest('li');
    if (!liTag) return;
    const newLang = liTag.dataset.lang;
    goToLanguage(newLang);
};

export default (selector) => {
    const container = document.getElementById(selector);
    if (!container) return;

    const elm = {
        dropdown: container.querySelector('.dropdown-menu')
    };

    // container.addEventListener('shown.bs.dropdown', positionLangDropDown);
    elm.dropdown.addEventListener('click', (e) => changeLanguage(e));
};
