import { escapeHtml } from 'src/js/utils';
import templates from './templates';

const processUserData = (data) => {
    const [email, , firstName = '', lastName = ''] = data;
    const first = firstName.length > 0 ? firstName : '';
    const last = lastName.length > 0 ? lastName : '';
    const fullName = `${first} ${last}`.trim();
    const name = escapeHtml(fullName);

    return { email, name };
};

export const getMapiUrl = (email, mapiHost) => `//${mapiHost}/v2/mailbox/${email}/folders/recent`;

export const processItems = (accounts, urlList, baseId) => {
    const imgIdsList = [];
    const accountsList = [];
    const itemsList = accounts.map((account, idx) => {
        const imgId = baseId + idx;
        const urlParts = [...urlList];
        urlParts.splice(1, 0, account.data[0]);
        const aHref = urlParts.join('');
        imgIdsList.push(imgId);
        const parsedAccount = processUserData(account.data);
        accountsList.push(parsedAccount);

        return templates.get('item')(
            { aHref, imgId, data: parsedAccount, imgSrc: account.imgSrc }
        );
    }).join('');

    return { accountsList, imgIdsList, itemsList };
};
