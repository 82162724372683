import { getMapiUrl, processItems } from './utils';
import templates from './templates';

const renderAccounts = async ({args, container, inxClasses}) => {
    // console.log(args);
    const imgIdBase = 'account__avatar-';
    const {i18n, linkedAccounts, urlParts} = args;
    const addAcountHtml = templates.get('addAcount')(urlParts[0], i18n);

    if (linkedAccounts.length < 1) {
        container.innerHTML = addAcountHtml;
        return;
    }

    const {accountsList, imgIdsList, itemsList} = processItems(linkedAccounts, urlParts, imgIdBase);
    container.innerHTML = templates.get('itemsList')(itemsList) + addAcountHtml;

    linkedAccounts.forEach((account, idx) => {
        const avatarInstance = inxClasses.Avatar(`#${imgIdsList[idx]}`, accountsList[idx]);
        avatarInstance.check();
    });

    // Check recent
    try {
        const mapiURL = getMapiUrl(args.mainUser.email, args.mapiHost);
        const data = await fetch(mapiURL, {
            method: 'GET',
            credentials: 'include'
        });
        const jsonResponse = await data.json();
        if (!jsonResponse.linkedUsers) {
            return;
        }
        jsonResponse.linkedUsers.forEach((user) => {
            const {hasRecent, username} = user;
            const indicator = container.querySelector(`[data-user="${username}"]`);
            indicator.classList.toggle('on', hasRecent);
        });
    } catch (err) {
        console.log(err);
    }
};

export default (obj, INBOX) => {
    // console.log({obj});
    // Lazy load linked account images - only when sidebar is opened

    const offcanvasEl = document.querySelector('#side-menu');
    if (!offcanvasEl) {
        return;
    }

    offcanvasEl.addEventListener('show.bs.offcanvas', () => {
        const { containerId, ...args } = obj;
        const container = document.getElementById(containerId);

        if (!container) return;

        renderAccounts({ args, container, inxClasses: INBOX.classes });
    });
};
