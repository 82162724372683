import Alert from 'bootstrap/js/src/alert';
//todo test if this is still needed
//import './oldschool/affix'; /* copied from bootstrap 3 for ios (PORTAL-437) @elvis maybe not needed? */
import Button from 'bootstrap/js/src/button';
import Dropdown from 'bootstrap/js/src/dropdown';
import Modal from 'bootstrap/js/src/modal';
import Offcanvas from 'bootstrap/js/src/offcanvas';

window.bootstrap = {
    Alert: Alert,
    Button: Button,
    Dropdown: Dropdown,
    Modal: Modal,
    Offcanvas: Offcanvas,
};
