// Breakpoints of Bootstrap 3 (in px)
export const BREAKPOINTS = {
    xs: 480,
    sm: 768,
    md: 992,
    ld: 1200
};

// Ready to use media queries
export const MEDIA_QUERIES = ((bpList) => {
    const { sm, md, ld } = bpList;
    return {
        xs: `(max-width: ${sm - 1}px)`,
        sm: `(min-width: ${sm}px) and (max-width: ${md}px)`,
        md: `(min-width: ${md - 1}px) and (max-width: ${ld - 1}px)`,
        ld: `(min-width: ${ld}px)`
    };
})(BREAKPOINTS);
