function gaTrackerSend(action) {
    if (window.ga) {
        window.ga('otherTracker.send', 'event', 'Toolbar', action, 'horizontal', 1);
    }
}

function appendCollection(itemsToAppend, container) {
    for (let item of itemsToAppend) {
        if (container.contains(item)) {
            continue;
        }
        container.append(item);
    }
}

function resetList(container, list) {
    appendCollection(list, container);
}

function onWinResize(pBar) {
    var me    = this,
        list = this.items;

    pBar.style.visibility = 'hidden';
    resetList(pBar, list);

    clearTimeout(window.resizedFinished);
    window.resizedFinished = setTimeout(function () {
        pBar.style.visibility = 'visible';
        me.render();
        // console.log('Resized finished.');
    }, 50);
}

function getVisibleItemsCount(container, itemsList) {
    var counter       = 0,
        itemsCount    = itemsList.length,
        ocuppiedWidth = 0,
        reserved      = 60,
        wrapperWidth  = container.parentNode.offsetWidth;

    do {
        var item = itemsList[counter];
        ocuppiedWidth = Math.round(item.offsetLeft + item.offsetWidth);
        if ((wrapperWidth - ocuppiedWidth) < reserved) {
            break;
        }
        counter++;
        // console.log(counter + '. Ocupied width: %s', ocuppiedWidth);
    } while (counter < itemsCount);
    // console.log('Container width %s', wrapperWidth);
    // console.info('visible: %s', counter);
    return counter;
}

function calculateMenuItems(container, list) {
    var visibleCount = getVisibleItemsCount(container, list);
    var hiddenCount  = list.length - visibleCount;
    var items = {};

    if (hiddenCount > 0) {
        items.hidden  = list.slice(visibleCount);
        items.visible = list.slice(0, visibleCount);
    }
    else {
        items.hidden  = [];
        items.visible = list;
    }
    return items;
}

export default (function () {
    var instance = null,
        productBarContainer,
        productBarList,
        moreMenuContainer,
        moreItemCount;

    var PBar = function (o) {
        productBarContainer = document.querySelector(o.prodListID);
        productBarList      = productBarContainer.querySelector('ul');
        moreMenuContainer   = document.querySelector(o.moreMenuID);
        moreItemCount       = document.querySelector(o.moreItemsCountID);

        this.align = o.align || 'left';

        this.items = [];
        for (let item of productBarList.children) {
            this.items.push(item);
        }

        // Events handler
        moreMenuContainer.addEventListener('shown.bs.dropdown', () => {
            gaTrackerSend('Open');
        });

        moreMenuContainer.addEventListener('hidden.bs.dropdown', () => {
            gaTrackerSend('Close');
        });
    };

    PBar.prototype.render = function () {
        var me           = this,
            currentItems = calculateMenuItems(productBarContainer, me.items);

        if (currentItems.hidden.length < 1 ) {
            moreMenuContainer.classList.add('inactive');
        }
        else {
            moreMenuContainer.classList.remove('inactive');

            const hiddenListUl = moreMenuContainer.querySelector('#product-menu__hidden-items');
            appendCollection(currentItems.hidden, hiddenListUl);

            moreItemCount.textContent = currentItems.hidden.length;
        }
        appendCollection(currentItems.visible, productBarList);

        productBarList.classList.add(this.align);
        productBarList.classList.remove('not-ready');

        window.onresize = () => {
            onWinResize.call(me, productBarList);
        };

        return this;
    };

    return function (params) {
        if (instance === null) {
            instance = new PBar(params);
        }
        return instance;
    };
}());
