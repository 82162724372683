/* global INBOX */
import initials from 'initials';

import { isStorageAvailable } from '../utils';
import defaultAvatarImg from 'src/img/misc/person.png';
import inx from 'inx';

const getInitials = (str) => {
    const result = initials(str);
    return result[0] + result.slice(-1);
};

// XXX: Refactor this someday
INBOX.namespace('classes.Avatar', function () {
    const spacerImgUrl = `${location.protocol}//${location.host}/assets/com/img/spacer.gif`;
    const isLSAvailable = isStorageAvailable('localStorage') && localStorage !== null;
    let iName = '.inx-profile__thumb';
    let instance = null;

    async function doReload(url) {
        if (mustReloadCache()) {
            try {
                img.reloadCached(url);
            } finally {
                await img.fetch(url);
            }
        } else {
            await img.fetch(url);
        }
    }

    function mustReloadCache() {
        if (isLSAvailable) {
            // Todo: this probably does not work if multiple avatars are on page (linked accounts)
            return (localStorage.reloadCachedImg === 'true') ? true : false;
        }
        return true;
    }

    const img = {
        // Spacer dimensions are: 1 x 1px
        isSpacer(img) {
            return (img.height < 2 && img.width < 2);
        },
        // Fetch image
        async fetch(iSrc) {
            return new Promise((resolve, reject) => {
                if (!iSrc) {
                    reject('No avatar image source');
                    return false;
                }
                const pic = new Image();
                pic.addEventListener('load', function () {
                    // console.log('Image loaded: %s', this.src);
                    if (img.isSpacer(this)) {
                        // console.info('image is spacer');
                        reject('image is spacer.gif- avatar does not exist');
                    }
                    else {
                        resolve('image found');
                    }
                });
                pic.addEventListener('error', function () {
                    // console.warn('Failed to load: %s', this.src);
                    reject('Failed to load ' + this.src);
                });
                pic.src = iSrc;
            });
        },
        getUrl(selector) {
            let url = null;
            const elm = document.querySelector(selector);
            if (!elm) return url;

            if ('src' in elm.dataset) {
                url = elm.dataset.src.trim();
                delete elm.dataset.src;
            }

            return url;
        },
        // Reload img from server
        async reloadCached(url) {
            url = url || instance.imgUrl;
            let firstLoad = true,
                iframe;

            return new Promise(function (resolve) {
                function loadCallback() {
                    if (firstLoad) {
                        firstLoad = false;
                        iframe.src = url; // Avoid 'Permission denied in IE 11
                        iframe.contentWindow.location.reload(true);
                        if (isLSAvailable) {
                            localStorage.reloadCachedImg = 'false';
                        }
                    }
                    else {
                        if (iframe.parentNode) {
                            iframe.parentNode.removeChild(iframe);
                        }
                        resolve('cache reloaded');
                    }
                }

                iframe = document.createElement('iframe');
                iframe.style.display = 'none';
                document.body.appendChild(iframe);
                iframe.addEventListener('load', loadCallback, false);
                iframe.addEventListener('error', loadCallback, false);
                iframe.src = url;
            });
        },
        /** Set avatar or default image
        * @param {object} iObj Contains img name, type and url
        */
        set(iObj = {}) {
            // console.log(iObj);
            const { user = {} } = iObj;
            const selector = iObj.iName || iName;
            const imgUrl = iObj.iUrl || spacerImgUrl;
            const imgType = iObj.iType || 'default';
            const items = document.querySelectorAll(selector);

            items.forEach((item) => {
                const itemParent = item.parentNode;

                if (itemParent?.classList?.contains('user-img')) {
                    const title = `${user.name}\u000A${user.email}`;
                    itemParent.setAttribute('title', title);
                }

                if (imgType === 'default') {
                    if ('initials' in item.dataset) {
                        const userInitials = getInitials(user.name) === 'undefined'
                            ? getInitials(user.email)
                            : getInitials(user.name);
                        const span = document.createElement('span');
                        span.classList.add('rounded-circle', 'user-initials');

                        if (userInitials === 'undefined' || userInitials === undefined) {
                            span.innerHTML = '<span class="ifi-profile"></span>';
                        } else {
                            span.textContent = userInitials;
                        }

                        item.replaceWith(span);
                    } else {
                        if (!item.classList.contains('ifi-profile')) {
                            item.style.backgroundImage = `url(${defaultAvatarImg})`;
                        }
                    }
                } else {
                    item.classList.replace('ifi-profile', 'img-thumbnail');
                    item.style.cssText = `background-image:url(${imgUrl});background-size:100%`;
                }
            });

            return imgType;
        }
    };

    /**
    * Constructor for avatar checking/setting/reloading
    * @this Avatar
    * @constructor
    * @param {string} name Avatar img selector name (class || id)
    */
    const Avatar = function (selector, user) {
        iName = selector || iName;
        // Current URL of avatar
        this.imgUrl = img.getUrl(iName);
        this.iNameX = iName;
        this.isFailed = null;
        this.checkType = null;

        this.isCheckFailed = () => {
            return this.isFailed;
        },

        this.getCheckType = () => {
            return this.checkType;
        },

        this.check = async () => {
            let type = null;
            try {
                await doReload(this.imgUrl);
                type = img.set({
                    user,
                    iType: 'setByUser',
                    iName: this.iNameX,
                    iUrl: this.imgUrl
                });
                this.isFailed = false;
            } catch (error) {
                type = img.set({
                    user,
                    iType: 'default',
                    iName: this.iNameX
                });
                this.isFailed = true;
            }
            if (user && user.profileImg) {
                // Set redirect url in case of empty avatar
                if (type === 'default') {
                    //console.log(user.profileImg);
                    //todo linkId selector .inx-link-to-profile not used anywhere
                    //document.querySelector(user.profileImg.linkId).setAttribute('href', user.profileImg.addUrl);
                }
            }
            this.checkType = type;

            return this;
        };
    };

    return function (selector, userData) {
        // console.log(inx.params.user);
        const user = userData || inx.params.user;

        return new Avatar(selector, user);
    };
});
