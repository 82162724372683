export default function (gestureZone, direction = 'right', callback) {
    let touchstartX = 0;
    let touchstartY = 0;

    gestureZone.addEventListener('touchstart', function(event) {
        touchstartX = event.changedTouches[0].screenX;
        touchstartY = event.changedTouches[0].screenY;
    }, { passive: true });

    gestureZone.addEventListener('touchend', function(event)  {
        const touchendX = event.changedTouches[0].screenX;
        const touchendY = event.changedTouches[0].screenY;

        // console.log({
        //     touchstartX,
        //     touchstartY,
        //     touchendX,
        //     touchendY
        // });

        if (direction === 'top' && touchendY > (touchstartY + 80)) {
            callback();
        }
        if (direction === 'bottom' && touchendY < (touchstartY - 80)) {
            callback();
        }
        if (direction === 'right' && touchendX > (touchstartX + 80)) {
            callback();
        }
        if (direction === 'left' && touchendX < (touchstartX - 80)) {
            callback();
        }
    }, { passive: true });
}
