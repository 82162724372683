import { trimSlashes } from '../utils';

// Construct mapi request with or without messages
export const getMapiUrl = (params) => {
    const
        {
            fetchMailBodies = false,
            mapiHost,
            userMail
        } = params,
        fetchMessages = fetchMailBodies ? '/messages?peek=true&limit=13' : '';

    return `//${trimSlashes(mapiHost)}/mailbox/${userMail}/folders/INBOX${fetchMessages}`;
};
