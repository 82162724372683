export const DOMAINS_LIST = ['lv', 'lt', 'ee'];

export const DEFAULTS = () => {
    const promoName = 'mailplus-promo';
    return {
        cookieName: promoName,
        i18n: {
            body:  'Upgrade to',
            body1:  'Premium',
            close: 'Close',
            txt1:  'Get premium',
            feature1: 'Ad-Free Mailbox',
            feature2: 'Unlimited Email Sending',
            feature3: 'Ample File Storage',
            feature4: 'Secure Backup for Private Photos & Videos!',
            or:  'or',
            txt1_2:  'Gift it',
            txt2:  'Later',
            txt3:  'Do not show this again'
        },
        domain: 'lv',
        theme: 'default',
        tracking: { // INFO: https://jira.dev.inbox.lv/browse/MAIL-3734
            clickTagID: 24065,
            clickTagIDs: {
                upgrade: 24065,
                gift: 24065 //@todo this needs to be another banner id as it's links ends with &user_choose=gift
            },
            pixelIDS: {
                close: 21067,
                dontShowAgain: 21069,
                later: 21068
            },
        },
        ttl: {
            close: 60 * 60 * 24 * 62,// 62 days
            later: 60 * 60 * 24 * 10 // 10 days
        }
    };
};
