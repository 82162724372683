/* eslint-disable */
var INBOX = INBOX || {};
INBOX.namespace = function (namespace, application) {
    var ns = namespace.split('.');
    var that = this;

    for(var i = 0; i < ns.length; i++){
        if(typeof that[ns[i]] === 'undefined')
            that[ns[i]] = {};
        that = that[ns[i]];
    }

    if (typeof(application) === 'function')
        switch (ns.length) {
        case 1: INBOX[ns[0]] = new application(); break;
        case 2:
            try {
                INBOX[ns[0]][ns[1]] = new application();
            } catch (err) {
                INBOX[ns[0]][ns[1]] = application;
            }
            break;
        case 3: INBOX[ns[0]][ns[1]][ns[2]] = new application(); break;
        case 4: INBOX[ns[0]][ns[1]][ns[2]][ns[3]] = new application(); break;
        case 5: INBOX[ns[0]][ns[1]][ns[2]][ns[3]][ns[4]] = new application(); break;
        case 6: INBOX[ns[0]][ns[1]][ns[2]][ns[3]][ns[4]][ns[5]] = new application(); break;
        default:
            throw 'Namespace of level 7 is not uspported';
        }
};

INBOX.namespace('classes.Observer', function() {
    var Observer = function(){
        this.listeners = {};
    };

    Observer.prototype = {
        constructor: INBOX.classes.Observer,

        observe: function(event, callback){
            if (typeof(callback) !== 'function') {
                return false;
            }
            var events = ('' + event).toLowerCase().split(/\s+/);
            for (var i = 0, item; (item = events[i]); i++) {
                if (this.listeners[item] === void(0)) {
                    this.listeners[item] = [];
                }
                this.listeners[item].push(callback);
            }
            return this;
        },

        unobserve: function(event/*, callback*/){
            //if(typeof(callback) !== 'function') return false;
            event = ('' + event).toLowerCase().split(/\s+/);
            for (var i = 0; i < event.length; i++) {
                if (typeof(this.listeners[event[i]]) === 'object') {
                    delete(this.listeners[event[i]]);
                }
            }
            return this;
        },

        notify: function(event, target){
            event = event.toLowerCase();
            var	handlers = this.listeners[event] || [];

            if(handlers.length > 0){
                const customEvent = new Event(event);
                for(var i = 0; i < handlers.length; i++){
                    try{
                        if(handlers[i](customEvent, target) === false || customEvent.defaultPrevented){
                            break;
                        }
                    }
                    catch(ex){
                        console.log(ex);
                    }
                }
            }
            return this;
        }
    };

    Observer.inherit = function (object) {
        var i;

        for (i in Observer.prototype) {
            if (Observer.prototype.hasOwnProperty(i) && typeof Observer.prototype[i] === 'function'){
                object[i] = Observer.prototype[i];
            }
        }
        object.listeners = {};
    };

    return Observer;
});


INBOX.namespace('observer', function () {
    return new INBOX.classes.Observer();
});

window.INBOX = INBOX;

export default INBOX;
