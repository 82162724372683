export default (o) => {
    const {
            dismissible,
            hasIcon = false,
            text,
            type
        } = o,
        alertClass = [];
    let closeButton = '';

    if (dismissible) {
        alertClass.push('alert-dismissible');
        closeButton = '<button aria-label="Close" class="close ico ipi-close" data-bs-dismiss="alert" data-dismiss="alert" type="button"></button>';
    }
    if (hasIcon) {
        alertClass.push('inx-alert_has-icon');
    }
    return `
        <div class="alert alert-${type} inx-alert ${alertClass.join(' ')}" role="alert">
            ${dismissible ? closeButton : ''}
            ${text}
        </div>
    `;
};
